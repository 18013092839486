import { RequestNoteVM } from './RequestViewModels.js';
import { FrequencyChartColors } from './FrequencyChart.js';

var AssignFrequenciesConst = {
  EquipPowerGraphFactor: 1.0,
  TVPowerGraphFactor: 0.1,
  PendingCountGraphFactor: 10,
  MinGraphHeight: 15,
  MaxGraphHeight: 95
};

var EquipmentRequestType = {
  Request: 1,
  Static: 2,
  WalkIn: 3
};

var RequestStatus = {
  Submitted: 1,
  InProcess: 2,
  Completed: 3,
  Cancelled: 4,
  Draft: 5,
  CheckedIn: 7
};

var RequestStatusDescr = function (statusID) {
  switch (statusID) {
    case 1:
      return 'Submitted';
    case 2:
      return 'In Process';
    case 3:
      return 'Completed';
    case 4:
      return 'Cancelled';
    case 5:
      return 'Draft';
    case 7:
      return 'Checked-In';
    default:
      return '';
  }
};

var FrequencyAssignmentStatus = {
  Pending: 0,
  Approved: 1,
  EFCAssigned: 2,
  Rejected: 3,
  PartiallyApproved: 4,
  ExplicitlyRejected: 5
};

var SecureFrequencyType = {
  Standard: 0,
  Confidential: 1,
  Secure: 2
};

var EquipmentStatus = {
  Pending: 0,
  FullApproved: 1,
  PartApproved: 2,
  Rejected: 3
};

var FrequencyUseConflictType = {
  Unknown: 0,
  Rejected: 1,
  Pending: 2,
  Intermod: 3,
  Spacing: 4,
  AssignedBandwidth: 5,
  AssignedCenter: 6
};

var InterModOrder = {
  Second: 2,
  Third: 3,
  Fifth: 5,
  Seventh: 7
};

var AssignEventFrequenciesVM = function (data) {
  var self = this;

  self.Type = data.Type || EquipmentRequestType.Request;
  self.PageItemIDs = data.PageItemIDs || [];

  // Keep current item on page refresh
  if (sessionStorage.getItem('currentIdx') && sessionStorage.getItem('currentIdx') > 0) {
    self.InitialPageItemID = self.PageItemIDs[sessionStorage.getItem('currentIdx')] || -1;
    self.InitialEquipmentID = -1;
  } else {
    self.InitialPageItemID = data.SelectedPageItemID || -1;
    self.InitialEquipmentID = data.SelectedEquipmentID || -1;
  }
  self.InitialFilteredEquipmentIDs = data.FilteredEquipmenIDs || [];

  self.EventID = data.EventID || 0;
  self.EventName = data.EventName || '';
  self.VenueName = data.VenueName || '';
  self.EventDate = data.EventDate || null;
  self.Activities = (data.Activities || []).map(function (a) {
    return new ActivityAssignment(a);
  });

  self.GetPageVM = function (pagedata, idx) {
    if (self.Type == EquipmentRequestType.WalkIn) return new AssignWalkInPage(pagedata, self, idx);
    else return new AssignRequestPage(pagedata, self, idx);
  };
};

var AssignAreaFrequenciesVM = function (data) {
  var self = this;

  self.Type = data.Type || EquipmentRequestType.Static;
  self.PageItemIDs = data.PageItemIDs || [];

  // Keep current item on page refresh
  if (sessionStorage.getItem('currentIdx') && sessionStorage.getItem('currentIdx') > 0) {
    self.InitialPageItemID = self.PageItemIDs[sessionStorage.getItem('currentIdx')] || -1;
    self.InitialEquipmentID = -1;
  } else {
    self.InitialPageItemID = data.SelectedPageItemID || -1;
    self.InitialEquipmentID = data.SelectedEquipmentID || -1;
  }
  self.InitialFilteredEquipmentIDs = data.FilteredEquipmenIDs || [];

  self.AreaID = data.AreaID || 0;
  self.AreaName = data.AreaName || '';
  self.StaticFrequencySets = (data.StaticFrequencySets || []).map(function (s) {
    return new StaticSetAssignment(s);
  });

  self.GetPageVM = function (pagedata, idx) {
    return new AssignStaticPage(pagedata, self, idx);
  };
};

var AssignRequestPage = function (data, eventVM, idx) {
  var self = this;
  self.RequestID = data.RequestID || 0;
  self.RequestorName = data.RequestorName || '';
  self.CompanyName = data.CompanyName || '';
  self.RequestorEmail = data.RequestorEmail || '';
  self.RequestorMobile = data.RequestorMobile || '';
  self.RequestDate = data.RequestDate || null;
  self.EventName = eventVM.EventName;
  self.VenueName = eventVM.VenueName;
  self.EventDate = eventVM.EventDate;
  self.RequestStatusID = ko.observable(data.RequestStatusID || 0);
  self.Equipments = ko.observableArray(
    (data.Equipments || []).map(function (e, idx) {
      return new EquipmentAssignment(e, idx);
    })
  );
  self.RequestNotes = ko.observableArray(
    (data.RequestNotes || []).map(function (n) {
      return new RequestNoteVM(n);
    })
  );
  self.CanCheckIn = ko.pureComputed(function () {
    return self.RequestStatusID() == RequestStatus.Completed;
  });
  self.IsCheckedIn = ko.pureComputed(function () {
    return self.RequestStatusID() == RequestStatus.CheckedIn;
  });

  self.ItemIdx = idx;
  self.ItemID = self.RequestID;
  self.ProcID = eventVM.EventID;

  self.RequestStatusDescr = ko.pureComputed(function () {
    return RequestStatusDescr(self.RequestStatusID());
  });

  self.DynamicEquipLoadedCount = 0;

  self.UpdateEquipmentData = function (data, equipCount) {
    self.Equipments().forEach(function (e) {
      var current = data.find(function (x) {
        return x.EquipmentID == e.EquipmentID;
      });
      if (current != null) {
        e.UpdateData(current);
        self.DynamicEquipLoadedCount++;
        if (self.DynamicEquipLoadedCount == equipCount) $('#loadingFrequencies').hide();
      }
    });
  };
};

var AssignWalkInPage = function (data, eventVM, idx) {
  var self = this;
  self.EventID = data.EventID || 0;
  self.EventName = eventVM.EventName || '';
  self.RequestStatusID = ko.observable(data.RequestStatusID || 0);
  self.Equipments = ko.observableArray(
    (data.Equipments || []).map(function (e, idx) {
      return new EquipmentAssignment(e, idx);
    })
  );

  self.ItemIdx = idx;
  self.ItemID = self.EventID;
  self.ProcID = eventVM.EventID;
};

var AssignStaticPage = function (data, areaVM, idx) {
  var self = this;
  self.StaticFrequencySetID = data.StaticFrequencySetID || 0;
  self.SetName = data.SetName || '';
  self.AreaID = areaVM.AreaID || 0;
  self.AreaName = areaVM.AreaName || '';
  self.LocationName = data.LocationName || '';
  self.RequestStatusID = ko.observable(data.RequestStatusID || 0);
  self.Equipments = ko.observableArray(
    (data.Equipments || []).map(function (e, idx) {
      return new EquipmentAssignment(e, idx);
    })
  );

  self.ItemIdx = idx;
  self.ItemID = self.StaticFrequencySetID;
  self.ProcID = self.AreaID;

  self.DynamicEquipLoadedCount = 0;

  self.UpdateEquipmentData = function (data, equipCount) {
    self.Equipments().forEach(function (e) {
      var current = data.find(function (x) {
        return x.EquipmentID == e.EquipmentID;
      });
      if (current != null) {
        e.UpdateData(current);
        self.DynamicEquipLoadedCount++;
        if (self.DynamicEquipLoadedCount == equipCount) $('#loadingFrequencies').hide();
      }
    });
  };
};

var ActivityAssignment = function (data) {
  var self = this;
  self.ActivityID = data.ActivityID || 0;
  self.PartialFlag = data.PartialFlag || false;
  self.BaseActivityID = data.BaseActivityID || self.ActivityID;
  self.ActivityCode = data.ActivityCode || '';
  self.Name = data.Name || '';
  self.Description = data.Description || '';
  self.LocationID = data.LocationID || 0;
  self.LocationName = data.LocationName || '';
  self.GameDrivenTime = data.GameDrivenTime || false;
  self.StartTimeDisplay = data.StartTimeDisplay || '';
  self.EndTimeDisplay = data.EndTimeDisplay || '';

  self.BitFlag = 0; // set later
  self.ShowInList = ko.observable(false);
  self.Selected = ko.observable(false);
  self.ToggleSelected = function () {
    self.Selected(!self.Selected());
  };
};

var StaticSetAssignment = function (data) {
  var self = this;
  self.StaticFrequencySetID = data.StaticFrequencySetID || 0;
  self.Name = data.Name || '';
  self.LocationID = data.LocationID || 0;
  self.LocationName = data.LocationName || '';

  self.BitFlag = 0; // set later
  self.ShowInList = ko.observable(false);
  self.Selected = ko.observable(true);
  self.ToggleSelected = function () {
    self.Selected(!self.Selected());
  };
};

var EquipmentAssignment = function (data, idx) {
  var self = this;

  self.EquipmentID = data.EquipmentID || 0;
  self.ParentID = data.ParentID || 0;
  self.CanShowDetails = data.CanShowDetails === undefined ? true : data.CanShowDetails || false;
  self.EquipmentStatusID = ko.observable(data.EquipmentStatusID || 0);
  self.ManufacturerName = data.ManufacturerName || '';
  self.ModelName = data.ModelName || '';
  self.EquipmentTypeName = data.EquipmentTypeName || '';
  self.RequiredUseName = data.RequiredUseName || '';
  self.HowUsed = data.HowUsed || '';
  self.SignalTypeName = data.SignalTypeName || '';
  self.Power = data.Power || 0;
  self.Bandwidth = data.Bandwidth || 0;
  self.PriorityBandwidth = data.PriorityBandwidth || 0;
  self.TotalBandwidth = self.Bandwidth + self.PriorityBandwidth;
  self.RequestorContactInfo = data.RequestorContactInfo || '';
  self.RequestDate = data.RequestDate || '';
  self.RequestorName = data.RequestorName || '';
  self.RequestorCompany = data.RequestorCompany || '';
  self.NumPrimaryFreq = data.NumPrimaryFreq || null;
  self.NumBackupFreq = data.NumBackupFreq || null;
  self.Fixed = data.Fixed || false;
  self.FCC = data.FCC || false;
  self.CallSign = data.CallSign || '';
  self.Notes = data.Notes || '';
  self.AssignmentCompleted = ko.observable(data.AssignmentCompleted || false);
  self.AssignmentStarted = ko.observable(data.AssignmentStarted || false);
  self.IsProcessed = data.isProcessed;
  self.CanEditRequestEquipment = data.CanEditRequestEquipment;

  self.RequestedActivityIDs = data.RequestedActivityIDs || [];
  self.AvailableFrequencyBlocks = data.AvailableFrequencyBlocks || []; // kvp<int, string>
  self.DefaultFrequencyBlock = data.DefaultFrequencyBlock ? new FrequencyBlockSegmentList(data.DefaultFrequencyBlock) : null;

  self.Frequencies = ko.observableArray(
    (data.Frequencies || []).map(function (f) {
      return new AssignedFrequency(f);
    })
  );

  self._equipmentTrack = idx || data._equipmentTrack || 0;

  self.EquipmentStatusIconClass = ko.pureComputed(function () {
    switch (self.EquipmentStatusID()) {
      case 0:
        return 'glyphicon glyphicon-inbox';
      case 1:
      case 2:
        return 'glyphicon glyphicon-ok-sign';
      case 3:
        return 'glyphicon glyphicon-ban-circle';
      default:
        return '';
    }
  });

  self.EquipmentStatusIconColor = ko.pureComputed(function () {
    switch (self.EquipmentStatusID()) {
      case 0:
        return 'grey';
      case 1:
        return 'green';
      case 2:
        return 'orange';
      case 3:
        return 'red';
      default:
        return 'grey';
    }
  });

  self.EquipmentStatusTooltip = ko.pureComputed(function () {
    switch (self.EquipmentStatusID()) {
      case 0:
        return 'Equipment Request Pending';
      case 1:
        return 'Equipment Request Coordinated';
      case 2:
        return 'Equipment Request Partially Coordinated';
      case 3:
        return 'Equipment Request Rejected';
      default:
        return '';
    }
  });

  self.HasFrequencyConflict = ko.pureComputed(function () {
    return self.Frequencies().some(function (f) {
      return f.ConflictActivityIDs().length > 0 || f.HasIntermodConflict();
    });
  });

  self.UpdateData = function (data) {
    var updatedAssignedFrequencies = (data.Frequencies || []).map(function (f) {
      return new AssignedFrequency(f);
    });
    self.Frequencies(updatedAssignedFrequencies);
    if (data.DefaultFrequencyBlock) self.DefaultFrequencyBlock = new FrequencyBlockSegmentList(data.DefaultFrequencyBlock);
  };
};

var FrequencyBlockSegmentList = function (data) {
  var self = this;
  self.FrequencyBlockID = data.FrequencyBlockID || 0;
  self.FrequencyBlockName = data.FrequencyBlockName || '';
  self.SegmentSize = data.SegmentSize || 0;
  self.AvailableSegments = data.AvailableSegments || []; //<int,string>
  self.FrequenciesBySegment = data.FrequenciesBySegment || []; //<decimal,int>
};

var AssignedFrequency = function (data) {
  var self = this;

  self.EquipmentFrequencyAssignmentID = data.EquipmentFrequencyAssignmentID || 0;
  self.EquipmentID = data.EquipmentID || 0;
  self.Frequency = ko.observable(data.Frequency || null);
  self.StatusID = ko.observable(data.StatusID || 0);
  self.Notes = ko.observable(data.Notes || '');

  self.AssignedActivityIDs = ko.observableArray(data.AssignedActivityIDs || []);
  self.ConflictActivityIDs = ko.observableArray(data.ConflictActivityIDs || []);
  self.HasIntermodConflict = ko.observable(data.HasIntermodConflict || false);

  self.FrequencyDisplay = ko.pureComputed(function () {
    if (self.Frequency()) {
      return self.Frequency().toFixed(5);
    } else {
      return '';
    }
  });

  self.DisplayClass = ko.pureComputed(function () {
    var conflictClass = self.ConflictActivityIDs().length > 0 || self.HasIntermodConflict() ? ' conflict' : '';
    switch (self.StatusID()) {
      case 0:
        return 'pending' + conflictClass;
      case 1:
        return 'approved' + conflictClass;
      case 2:
        return 'assigned' + conflictClass;
      case 3:
      case 5:
        return 'rejected' + conflictClass;
      case 4:
        return 'partapproved' + conflictClass;
      default:
        return '' + conflictClass;
    }
  });

  self.IsAssigned = ko.pureComputed(function () {
    return [1, 2, 4].indexOf(self.StatusID()) > -1;
  });
  self.IsPending = ko.pureComputed(function () {
    return self.StatusID() == 0;
  });
  self.IsRejected = ko.pureComputed(function () {
    return self.StatusID() == 3 || self.StatusID() == 5;
  });
  self.CanAddNote = ko.pureComputed(function () {
    return !self.Notes() && self.IsAssigned();
  });
  self.CanEditNote = ko.pureComputed(function () {
    return self.Notes() && self.IsAssigned();
  });
};

// bitmapFunc should accept array of "ConflictItemIDs" and return a bitmap representing conflicts in the current environment

var TVFrequencyUse = function (data, bitmapFunc) {
  var self = this;

  self.ActivityFlags = typeof bitmapFunc == 'function' ? bitmapFunc(data.ConflictItemIDs || []) : 0;

  self.TVStationID = data.TVStationID || 0;
  self.Channel = data.Channel || 0;
  self.LowerFrequency = data.LowerFrequency || 0; // MHz
  self.UpperFrequency = data.UpperFrequency || 0; // MHz
  self.CallSign = data.CallSign || '';
  self.Type = data.Type || '';
  self.IsHighPower = data.IsHighPower || false;
  self.ProtectionDistance = data.ProtectionDistance || null;
  self.Power = data.Power || null; // kW
  self.Distance = data.Distance || null; // miles
  self.RSL = data.RSL || null;
  self.Notes = data.Notes || '';
  self.DisplayColor = data.DisplayColor || null;
};

var EquipmentFrequencyUse = function (data, bitmapFunc) {
  var self = this;

  self.ActivityFlags = typeof bitmapFunc == 'function' ? bitmapFunc(data.ConflictItemIDs || []) : 0;

  self.EquipmentID = data.EquipmentID || 0;
  self.EquipmentRequestTypeID = data.EquipmentRequestTypeID || 0;
  self.FrequencyUseConflictTypeID = data.FrequencyUseConflictTypeID || 0;
  self.CenterFrequency = data.CenterFrequency || 0;
  self.Power = data.Power || 0; // mW
  self.TotalBandwidth = data.Bandwidth + data.PriorityBandwidth || 0; // MHz
  self.EquipmentParentID = data.EquipmentParentID || 0;
  self.EquipmentParentName = data.EquipmentParentName || '';
  self.RequestorName = data.RequestorName || '';
  self.CompanyName = data.CompanyName || '';
  self.RequestDate = data.RequestDate || null;
  self.SecureFrequencyTypeID = data.SecureFrequencyTypeID || 0;
  self.CanShowDetails = data.CanShowDetails;
  self.SignalTypeName = data.SignalTypeName || '';
  self.EquipmentTypeName = data.EquipmentTypeName || '';
  self.HowUsed = data.HowUsed || '';
  self.DisplayColor = data.DisplayColor || null;

  // Following properties only in "Detailed" use info
  self.Fixed = data.Fixed || false;
  self.RequiredUseName = data.RequiredUseName || '';
  self.ManufacturerName = data.ManufacturerName || '';
  self.ModelName = data.ModelName || '';
  self.FCC = data.FCC || '';
  self.CallSign = data.CallSign || '';
  self.Notes = data.Notes || '';
  self.ImodOrder = data.ImodOrder || null;
  self.RequestStaticSetName = data.RequestStaticSetName || '';

  self.IsStatic = function () {
    return self.EquipmentRequestTypeID == EquipmentRequestType.Static;
  };
  self.IsWalkIn = function () {
    return self.EquipmentRequestTypeID == EquipmentRequestType.WalkIn;
  };
  self.IsRequest = function () {
    return self.EquipmentRequestTypeID == EquipmentRequestType.Request;
  };

  self.IsPending = function () {
    return self.FrequencyUseConflictTypeID == FrequencyUseConflictType.Pending;
  };
  self.IsImod = function () {
    return self.FrequencyUseConflictTypeID == FrequencyUseConflictType.Intermod;
  };
  self.IsActive = function () {
    return !self.IsImod() && !self.IsPending();
  };

  self.ConflictLabel = ko.pureComputed(function () {
    if (self.FrequencyUseConflictTypeID == FrequencyUseConflictType.AssignedCenter) {
      return 'Currently Assigned';
    } else if (self.FrequencyUseConflictTypeID == FrequencyUseConflictType.AssignedBandwidth) {
      return 'Bandwidth Assigned (' + self.CenterFrequency.toFixed(5) + ')';
    } else if (self.FrequencyUseConflictTypeID == FrequencyUseConflictType.Spacing) {
      return 'Spacing Conflict (' + self.CenterFrequency.toFixed(5) + ')';
    } else if (self.FrequencyUseConflictTypeID == FrequencyUseConflictType.Pending) {
      return 'Requested Frequency';
    } else if (self.FrequencyUseConflictTypeID == FrequencyUseConflictType.Rejected) {
      return 'Frequency Rejected';
    } else return '';
  });
};

var CandidateFrequency = function (data) {
  var self = this;

  self.Frequency = data.Frequency || 0.0;
  self.FrequencyDisplay = self.Frequency.toFixed(5);

  self.isSelected = ko.observable(false);
  self.FrequencyNote = ko.observable('');
  self.FrequencyNoteEdit = ko.observable(false);
  self.FrequencyNoteEditDone = ko.observable(false);

  self.CurrentEquipmentPending = data.CurrentEquipmentPending;
  self.OtherEquipmentPending = data.OtherEquipmentUses;

  self.CandidateBalloonText = ko.pureComputed(function () {
    if (!self.CurrentEquipmentPending && self.OtherEquipmentPending.length > 0) {
      var balloonTxt = '';
      balloonTxt =
        'This frequency (' +
        self.FrequencyDisplay +
        ') is on the following ' +
        (self.OtherEquipmentPending.length == 1 ? 'request, ' : 'requests, ') +
        'but not yet assigned \n';

      // foreach self.OtherEquipmentPending.length
      self.OtherEquipmentPending.forEach(function (e) {
        balloonTxt = balloonTxt + ' \n ' + 'Request #: ' + e.EquipmentParentID + ' \n ';
        balloonTxt = balloonTxt + 'Request Date: ' + e.RequestDate + ' \n ';
        balloonTxt = balloonTxt + 'Requested By: ' + e.RequestorName + ' \n ';
        balloonTxt = balloonTxt + 'Company: ' + e.CompanyName + ' \n ';
      });
      return balloonTxt;
    } else return '';
  });

  self.PendingOnCurrent = ko.pureComputed(function () {
    return 'Requested Frequency';
  });

  self.EditNote = function (d, e) {
    var p = $(e.target).parents('.candidateNotes');
    self.FrequencyNoteEditDone(false);
    self.FrequencyNoteEdit(true);
    p.find('input').focus();
  };

  self.SaveNote = function () {
    self.FrequencyNoteEdit(false);
    self.FrequencyNoteEditDone(true);
  };

  self.ClearNote = function () {
    self.FrequencyNote('');
  };
  self.hovered = function (e) {
    console.log($($(e.target)[0]).offset());
    if ($($(e.target)[0]).offset().top < 275) {
      $('span.infoContainer').addClass('maxTop');
      return;
    }
    var parentTable = $($($(e.target)[0]).parents('div.table'));
    var tableMax = parentTable.height() + parentTable.offset().top;
    if ($($(e.target)[0]).offset().top > tableMax - 100) {
      $('span.infoContainer').addClass('maxBot');
    }
  };
  self.unhovered = function (e) {
    $('span.infoContainer').removeClass('maxTop');
    $('span.infoContainer').removeClass('maxBot');
  };

  self.textSetInput = function (d, e) {
    if (e.keyCode == 13) {
      self.SaveNote();
      return false;
    } else if (e.keyCode == 27) {
      e.preventDefault();
      e.stopPropagation();
      self.ClearNote();
      self.SaveNote();
      return false;
    } else return true;
  };
};

var _FrequencyDataBaseSetup = function (self, data, bitmapFunc, isEquipmentClosed) {
  // self passed in as instantiated object to get properties

  self.Frequency = data.Frequency || 0.0;
  self.FrequencyDisplay = self.Frequency.toFixed(5);

  self.TVFrequencyUses = (data.TVFrequencyUses || []).map(function (u) {
    return new TVFrequencyUse(u, bitmapFunc);
  });

  self.CurrentEquipmentUses = [];
  self.OtherEquipmentUses = [];
  self.ImodAll = [];
  (data.EquipmentFrequencyUses || []).forEach(function (u) {
    var use = new EquipmentFrequencyUse(u, bitmapFunc);
    if (use.IsImod()) self.ImodAll.push(use);
    else if (use.EquipmentID == self.EquipmentID) self.CurrentEquipmentUses.push(use);
    else self.OtherEquipmentUses.push(use);
  });
  self.ShowIModInGraph = data.HasIntermodConflict;
  self.IsCandidateFrequency = data.IsCandidateFrequency;

  self.CurrentEquipmentAssigned = null;
  self.CurrentEquipmentPending = false; //For Candidate Frequency
  if (self.CurrentEquipmentUses.length > 0) {
    self.CurrentEquipmentAssigned = self.CurrentEquipmentUses.find(function (u) {
      return u.FrequencyUseConflictTypeID == FrequencyUseConflictType.AssignedCenter;
    });
    var current = self.CurrentEquipmentUses.find(function (u) {
      return u.FrequencyUseConflictTypeID == FrequencyUseConflictType.Pending;
    });
    if (current) self.CurrentEquipmentPending = true;
  }

  self.ActiveTVUses = [];
  self.OtherEquipmentThisAssigned = [];
  self.OtherEquipmentThisCenterAssigned = [];
  self.OtherEquipmentActive = [];
  self.OtherEquipmentPending = [];
  self.ImodActive = [];

  self.ApplyFilter = function (conflictMask, eventGraph) {
    // These will filter based on selected activities
    self.ActiveTVUses = self.TVFrequencyUses.filter(function (u) {
      return u.ActivityFlags & conflictMask;
    });
    self.OtherEquipmentThisAssigned = self.OtherEquipmentUses.filter(function (u) {
      return (
        (u.FrequencyUseConflictTypeID == FrequencyUseConflictType.AssignedCenter ||
          u.FrequencyUseConflictTypeID == FrequencyUseConflictType.AssignedBandwidth) &&
        u.ActivityFlags & conflictMask
      );
    });
    self.OtherEquipmentThisCenterAssigned = self.OtherEquipmentUses.filter(function (u) {
      return u.FrequencyUseConflictTypeID == FrequencyUseConflictType.AssignedCenter && u.ActivityFlags & conflictMask;
    });
    self.OtherEquipmentActive = self.OtherEquipmentUses.filter(function (u) {
      return u.IsActive() && u.FrequencyUseConflictTypeID != FrequencyUseConflictType.Rejected && u.ActivityFlags & conflictMask;
    });
    self.OtherEquipmentPending = self.OtherEquipmentUses.filter(function (u) {
      return u.IsPending() && u.ActivityFlags & conflictMask;
    });
    self.ImodActive = self.ImodAll.filter(function (u) {
      return u.ActivityFlags & conflictMask;
    });

    if (!eventGraph) {
      self.Imod2ndOrder = ko.pureComputed(function () {
        return self.ImodActive.filter(function (i) {
          return i.ImodOrder == InterModOrder.Second;
        });
      });

      self.Imod3rdOrder = ko.pureComputed(function () {
        return self.ImodActive.filter(function (i) {
          return i.ImodOrder == InterModOrder.Third;
        });
      });

      self.Imod5thOrder = ko.pureComputed(function () {
        return self.ImodActive.filter(function (i) {
          return i.ImodOrder == InterModOrder.Fifth;
        });
      });

      self.Imod7thOrder = ko.pureComputed(function () {
        return self.ImodActive.filter(function (i) {
          return i.ImodOrder == InterModOrder.Seventh;
        });
      });
    }

    var isUsed =
      self.OtherEquipmentActive.length > 0 ||
      self.OtherEquipmentThisAssigned.length > 0 ||
      self.OtherEquipmentPending.length > 0 ||
      self.OtherEquipmentUses.length > 0 ||
      self.CurrentEquipmentUses.length > 0;
    self.BalloonText =
      '<span style="text-align: center;">' +
      self.FrequencyDisplay +
      (!isEquipmentClosed ? '<br>Click to ' + (self.CurrentEquipmentAssigned ? 'un' : '') + 'assign frequency ' + self.FrequencyDisplay : '') +
      (isUsed ? '<br>Right-Click for Detailed Conflict Information' : '') +
      '</span>';

    if (self._ApplyFilterOther) self._ApplyFilterOther(conflictMask);
  };
};

var FrequencyDataForGraph = function (data, equipID, bitmapFunc, assignmentCallback, graphItemBuilder, isEquipmentClosed) {
  var self = this;
  self.EquipmentID = equipID || data.TargetEquipmentID;
  _FrequencyDataBaseSetup(self, data, bitmapFunc, isEquipmentClosed);

  self.AssignmentCallback = typeof assignmentCallback == 'function' ? assignmentCallback : function (equipmentID, frequency, assigning) {};
  self.GraphItemBuilder =
    typeof graphItemBuilder == 'function'
      ? graphItemBuilder
      : function (freqData) {
          return {};
        };

  self.GraphItem = {};
  self.OwnEquipmentOutline = '';
  self.Warnings = [];

  self._ApplyFilterOther = function (conflictMask) {
    self.GraphItem = self.GraphItemBuilder(self);
    self.OwnEquipmentOutline = self.GraphItem.OwnBorder;
  };

  self.ToggleAssignment = function () {
    self.AssignmentCallback(self.EquipmentID, self.Frequency, self.CurrentEquipmentAssigned ? 'U' : 'A');
  };
};

var FrequencyDataDetailed = function (data, equipID, bitmapFunc, extraWarnings, forDashboard) {
  var self = this;
  self.EquipmentID = equipID || data.TargetEquipmentID;
  _FrequencyDataBaseSetup(self, data, bitmapFunc);

  self.SwitchSettings = data.SwitchSettings || '';
  self.StandardNote = data.StandardNote || '';
  self.CurrentNote = self.CurrentEquipmentAssigned ? self.CurrentEquipmentAssigned.Notes : '';
  self.OwnEquipmentOutline = '';

  self._origWarnings = extraWarnings || [];
  self.Warnings = self._origWarnings;

  if (typeof forDashboard == 'undefined') forDashboard = false;
  if (forDashboard) window.cursPos = false;

  self.HasConflict = function () {
    if (self.Warnings.length) return true;
    if (self.ActiveTVUses.length) return true;
    if (self.OtherEquipmentActive.length) return true;
    if (self.ImodActive.length) return true;
  };

  self._ApplyFilterOther = function (conflictMask) {
    self.Warnings = self._origWarnings;

    if (self.CurrentEquipmentUses.length > 1) {
      self.Warnings.push('Multiple Assignments for this Equipment and Frequency.');
    }

    if (!forDashboard) {
      if (self.CurrentEquipmentUses.length > 0) {
        // has something for current equipment

        switch (self.CurrentEquipmentUses[0].FrequencyUseConflictTypeID) {
          case FrequencyUseConflictType.AssignedCenter:
          case FrequencyUseConflictType.AssignedBandwidth:
            self.OwnEquipmentOutline = FrequencyChartColors.StrokeAssigned;
            break;
          case FrequencyUseConflictType.Spacing:
          case FrequencyUseConflictType.Intermod:
            self.OwnEquipmentOutline = FrequencyChartColors.StrokeWithin;
            break;
          case FrequencyUseConflictType.Pending:
            self.OwnEquipmentOutline = FrequencyChartColors.StrokePending;
            break;
          default:
            self.OwnEquipmentOutline = '';
            break;
        }
      } else if (self.ImodActive.length) {
        self.OwnEquipmentOutline = FrequencyChartColors.StrokeWithin;
      } else {
        // nothing for current equipment
        self.OwnEquipmentOutline = '';
      }
    }
  };
};

// same interface as FrequencyDataForGraph
var ContinuityDivider = function (frequency, targetEquipmentID) {
  var self = this;

  self.EquipmentID = targetEquipmentID;
  self.AssignmentCallback = function () {};
  self.GraphItemBuilder = function (freqData) {
    return {};
  };

  self.Frequency = frequency - 0.00001 || 0.0;
  self.FrequencyDisplay = '...';

  self.TVFrequencyUses = [];
  self.CurrentEquipmentUses = [];
  self.OtherEquipmentUses = [];
  self.ImodAll = [];
  self.CurrentEquipmentAssigned = null;
  self.BalloonText = '(Break in Frequency Pattern)';

  self.ActiveTVUses = [];
  self.OtherEquipmentThisAssigned = [];
  self.OtherEquipmentActive = [];
  self.OtherEquipmentPending = [];
  self.ImodActive = [];
  self.GraphItem = {
    Frequency: self.FrequencyDisplay,
    MaxVal: 100,
    Value: 100,
    Color: FrequencyChartColors.ContinuityDivider,
    OwnValue: 0,
    OwnColor: '',
    OwnBorder: '',
    OwnAlpha: 1,
    SBValue: 100,
    SBColor: FrequencyChartColors.ContinuityDivider,
    PendingValue: 0,
    ClickFunc: null,
    BalloonText: self.BalloonText
  };

  self.ApplyFilter = function (conflictMask) {};
  self.ToggleAssignment = function () {};
};

var InterModOptions = function (data) {
  var self = this;

  self.CompareEntityIDs = ko.observable('');
  self.SelectedIModCompareIDs = ko.observableArray([]);

  if (data) {
    self.ApplyIntermod = ko.observable(data.ApplyIntermod || false);
    self.EquipmentRequestTypeID = data.EquipmentRequestTypeID || null;
    self.Show2ndOrder = ko.observable(data.Show2ndOrder || false);
    self.Show3rdOrder = ko.observable(data.Show3rdOrder || false);
    self.Show5thOrder = ko.observable(data.Show5thOrder || false);
    self.Show7thOrder = ko.observable(data.Show7thOrder || false);
  } else {
    self.ApplyIntermod = ko.observable(false);
    self.EquipmentRequestTypeID = null;
    self.Show2ndOrder = ko.observable(false);
    self.Show3rdOrder = ko.observable(false);
    self.Show5thOrder = ko.observable(false);
    self.Show7thOrder = ko.observable(false);
  }
};

export {
  AssignFrequenciesConst,
  EquipmentRequestType,
  RequestStatus,
  EquipmentStatus,
  FrequencyUseConflictType,
  AssignEventFrequenciesVM,
  AssignAreaFrequenciesVM,
  ActivityAssignment,
  StaticSetAssignment,
  EquipmentAssignment,
  CandidateFrequency,
  FrequencyDataForGraph,
  FrequencyDataDetailed,
  ContinuityDivider,
  InterModOptions
};
