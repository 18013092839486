import { Common } from './Common.js';

var SiteMessageAddEditVM = function () {
  var self = this;

  if (RazorShared.isDebug) console.log('SiteMessageAddEditVM locator');

  self.$container = $(document);
  self.$form = $('');
  self.okCallback = function () {};

  self.SiteMessage = ko.observable(null);

  self.AddEditText = '';

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) self.okCallback = params.okCallback;

    var $title = self.$container.find('.modal-title');
    if (params.title) $title.text(params.title);

    // if (params.siteMessageID) self.ShowAddEdit(params.siteMessageID);
    // else self.ShowAddEdit(0);

    self.load(SiteMessageAddEditVM.jsonData);
  };

  self.load = function (jsonData) {
    self.SiteMessage = new SiteMessageVM(jsonData.SiteMessage);

    self.AddEditText =
      jsonData.SiteMessage && jsonData.SiteMessage.SiteMessageID ? 'Edit Site Message: ' + jsonData.SiteMessage.Name : 'Add Site Message';

    ko.applyBindings(self, self.$container[0]);
  };

  self.Save = function () {
    if (!self.$form.validate().form()) return;

    // console.log(self.$form.serialize());
    // console.log(ko.toJS(self.SiteMessage));

    $.post(RazorShared.baseUrl + 'SiteMessage/SaveSiteMessage', ko.toJS(self.SiteMessage), function (data) {
      if (!data.success) {
        Common.Dialog('Error saving site message', null, data.error);
      } else {
        if (self.SiteMessage.SiteMessageID == 0) {
          self.SiteMessage.SiteMessageID = data.Model.SiteMessageID;
        }
        self.$container.modal('hide');
        self.okCallback();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Site Message', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

var SiteMessageVM = function (jsonData) {
  var self = this;

  self.SiteMessageID = jsonData.SiteMessageID || 0;
  self.Name = ko.observable(jsonData.Name || '');
  self.Message = ko.observable(jsonData.Message || '');
  self.StartDate = ko.observable(jsonData.StartDate || null);
  self.EndDate = ko.observable(jsonData.EndDate || null);
  self.ShowBeforeUserLogin = ko.observable(jsonData.ShowBeforeUserLogin || false);
};

export default SiteMessageAddEditVM;
