import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';
import Cookies from 'js-cookie';

var BlockedFrequencyRangeAddEditVM = function () {
  var self = this;
  self.$container = $(document);
  self.$form = $('');
  self.BlockedFrequencyRange = ko.observable(null);
  self.AvailAreas = ko.observableArray([]);
  self.AvailLocations = ko.observableArray([]);

  self.BlockedRangeID = ko.observable(0);
  self.Name = ko.observable();
  self.SelectedArea = ko.observable();
  self.SelectedLocation = ko.observable();
  self.StartFrequency = ko.observable();
  self.EndFrequency = ko.observable();
  self.AvailAreasIDs = ko.observableArray([]);
  self.StaticSetMgm = ko.observable();

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) self.okCallback = params.okCallback;
    if (params.addAnother) self.AddAnother(params.addAnother);

    var $title = self.$container.find('.modal-title');
    if (params.title) $title.text(params.title);
    self.load(BlockedFrequencyRangeAddEditVM.jsonData);
  };

  self.load = function (jsonData) {
    // load models & arrays
    self.AvailAreas(jsonData.AvailAreas || []);
    self.AvailLocations(jsonData.AvailLocations || []);

    self.BlockedFrequencyRange = new BlockedFrequencyRangeVM(jsonData.BlockedFrequencyRange);

    if (self.BlockedFrequencyRange.Location) {
      console.log('select area');
      self.SelectedArea(self.BlockedFrequencyRange.Location.AreaID);
    }

    ko.applyBindings(self, self.$container[0]);

    self.Name.subscribe(function (name) {
      self.BlockedFrequencyRange.Name(name);
    });

    self.SelectedArea.subscribe(function (area) {
      self.reloadAvailLocations(area);
    });

    self.BlockedFrequencyRange.LocationID.subscribe(function (locationID) {
      self.SelectedLocation(locationID);
      self.validateBlockName(locationID);
    });

    if (self.BlockedFrequencyRange.BlockedRangeID() == 0 && self.AvailAreas().length == 1) self.SelectedArea(self.AvailAreas()[0].ID);
  };

  self.reloadAvailLocations = function (areaID) {
    if (!areaID) {
      self.BlockedFrequencyRange.LocationID(null);
      self.AvailLocations.removeAll();
      return;
    }
    $.get(RazorShared.baseUrl + 'StaticFrequency/GetLocationsForArea', { areaID: areaID }, function (data) {
      if (data.success) {
        self.AvailLocations(data.AvailLocations.sort(Common.StringSortFunc('Name')));
        if (self.AvailLocations().length == 1) self.BlockedFrequencyRange.LocationID(self.AvailLocations()[0].ID);
      } else {
        Common.Dialog('Error finding locations', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Locations', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.validateBlockName = function (locationID) {
    if (self.BlockedFrequencyRange.Name() && self.BlockedFrequencyRange.Name().length > 1) {
      self.$form.validate().element('input[id="Name"]');
    }
  };

  self.Save = function () {
    var $blockedFrequencyRangeForm = self.$container.find('#BlockFrequencyRangeForm');

    //VALIDATE...
    if (!self.$form.validate().form()) return false;

    var postdata = $blockedFrequencyRangeForm.serialize();

    Common.BlockUI();
    $.post(RazorShared.baseUrl + 'StaticFrequency/SaveBlockedFrequencyRange', postdata, function (data) {
      if (!data.success) {
        $.unblockUI();
        Common.Dialog('Error saving request', null, data.error);
        if (data.emailError && data.emailError == true) {
          self.$container.modal('hide');
        }
      } else {
        $.unblockUI();
        self.$container.modal('hide');
        self.okCallback();
      }
    })
      .fail(function (XMLHttpRequest, textStatus, errorThrown) {
        $.unblockUI();
        Common.Dialog('Save Request', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
      })
      .always(function () {
        //self.submitBtnDisabled(false);
      });
  };
};

var BlockedFrequencyRangeVM = function (jsonData) {
  var self = this;
  self.BlockedRangeID = ko.observable(jsonData.BlockedRangeID || 0);
  self.Name = ko.observable(jsonData.Name || '');
  self.AreaID = ko.observable(jsonData.AreaID || 0);
  self.Location = jsonData.Location;
  self.LocationID = ko.observable(jsonData.LocationID || 0);
  self.StartFrequency = ko.observable(jsonData.StartFrequency || null);
  self.EndFrequency = ko.observable(jsonData.EndFrequency || null);
};

export default BlockedFrequencyRangeAddEditVM;
