import { RequestOnsiteContactVM, OnsiteContactVM } from './RequestViewModels.js';
import { Common, EncodeHTML } from './Common.js';
import DialogMgr from './DialogMgr.js';

var RequestOnsiteContactsDialogVM = function () {
  if (RazorShared.isDebug) console.log('RequestOnsiteContactDialogVM locator');

  var self = this;
  self.$container = $(document);
  self.$form = $('');

  self.okCallback = function () {};

  self.RequestID = null;
  self.AppUserID = null;
  self.AvailOnsiteContacts = ko.observableArray([]);
  self.RequestOnsiteContacts = ko.observableArray([]);
  self.SelContactIDs = ko.observableArray([]);

  self.init = function ($dlgcontainer, params) {
    if ($dlgcontainer) {
      self.$container = $dlgcontainer;
      self.$form = self.$container.find('form');
    }

    if (params.okCallback) self.okCallback = params.okCallback;

    var $title = self.$container.find('.modal-title');
    if (params.title) $title.text(params.title);

    var initData = RequestOnsiteContactsDialogVM.jsonData;

    self.load(RequestOnsiteContactsDialogVM.jsonData);
  };

  self.load = function (jsonData) {
    if (!jsonData) return;

    self.RequestID = jsonData.RequestID;
    self.AppUserID = jsonData.AppUserID;

    self.AvailOnsiteContacts(
      jsonData.AvailOnsiteContacts.map(function (c, idx) {
        return new OnsiteContactVM(c, idx);
      }) || []
    );

    self.RequestOnsiteContacts = ko.observableArray(
      jsonData.RequestOnsiteContacts.map(function (c) {
        return new RequestOnsiteContactVM(c);
      }) || []
    );

    if (self.RequestOnsiteContacts() && self.RequestOnsiteContacts().length > 0) {
      self.RequestOnsiteContacts().forEach(function (rc) {
        var sac = self.AvailOnsiteContacts().find(function (ac) {
          return ac.OnsiteContactID == rc.OnsiteContactID;
        });
        if (sac != null && sac != undefined) {
          self.SelContactIDs.push(sac._contactTrack);
          sac.SendEmails = rc.SendEmails;
        }
      });
    }

    ko.applyBindings(self, self.$container[0]);
  };

  self.SelectedContacts = ko.pureComputed(function () {
    var list = [];

    self.SelContactIDs().forEach(function (id) {
      var c = self.AvailOnsiteContacts().find(function (ac) {
        return ac._contactTrack == id;
      });
      if (c != null && c != undefined) list.push(c);
    });

    return list;
  });

  self.RequestOnsiteContactRemoved = ko.pureComputed(function () {
    var currentSelection = self.SelectedContacts();
    var removedContactIDs = [];

    self.RequestOnsiteContacts().forEach(function (rc) {
      var c = currentSelection.find(function (c) {
        return rc.OnsiteContactID == c.OnsiteContactID;
      });
      if (!c) removedContactIDs.push(rc.RequestOnsiteContactID);
    });
    return removedContactIDs;
  });

  self.AddOnsiteContact = function () {
    DialogMgr.ChildAddOnsiteContact('Add Onsite Contact', self.OnsiteContactAdded, { AppUserID: self.AppUserID });
  };

  self.OnsiteContactAdded = function (newContact) {
    if (newContact) {
      var newidx = self.AvailOnsiteContacts().length + 1;
      var newContactVM = new OnsiteContactVM(newContact, newidx);
      self.AvailOnsiteContacts.push(newContactVM);
      self.SelContactIDs.push(newidx);
    }
  };

  self.EditOnsiteContact = function (contact) {
    DialogMgr.ChildEditOnsiteContact('Edit Onsite Contact', self.OnsiteContactEdited, ko.toJS(contact));
  };

  self.OnsiteContactEdited = function (contact) {
    if (contact) {
      var existIdx = self.AvailOnsiteContacts().indexOf(
        self.AvailOnsiteContacts().find(function (c) {
          return c._contactTrack == contact._contactTrack;
        })
      );
      if (existIdx > -1) {
        self.AvailOnsiteContacts.splice(existIdx, 1, new OnsiteContactVM(contact, existIdx));
      }
    }
  };

  self.Save = function () {
    Common.BlockUI();
    var saveOnsiteContactsCounter = self.AvailOnsiteContacts().length;
    self.AvailOnsiteContacts().forEach(function (contact) {
      $.post(RazorShared.baseUrl + 'OnsiteContacts/SaveContact', { Contact: contact }, function (data) {
        if (!data.success) {
          Common.Dialog('Error saving contact', null, data.error);
          $.unblockUI();
        } else {
          if (contact.OnsiteContactID == 0 && data.Contact.OnsiteContactID > 0) {
            contact.OnsiteContactID = data.Contact.OnsiteContactID;
          }
          saveOnsiteContactsCounter = saveOnsiteContactsCounter - 1;
          if (saveOnsiteContactsCounter == 0) {
            self.SaveRequestOnsiteContact();
          }
        }
      }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
        Common.Dialog('Save Contact', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
        $.unblockUI();
      });
    });
  };

  self.SaveRequestOnsiteContact = function () {
    var contactsToRemove = self.RequestOnsiteContactRemoved();
    var origRequestOnsiteContact = [];

    self.RequestOnsiteContacts().forEach(function (c) {
      origRequestOnsiteContact.push(c);
    });
    self.RequestOnsiteContacts.removeAll();
    self.SelectedContacts().forEach(function (contact) {
      var c = new RequestOnsiteContactVM(contact);
      c.RequestID = self.RequestID;
      if (contact.OnsiteContactID > 0) {
        var match = origRequestOnsiteContact.find(function (c) {
          return c.OnsiteContactID == contact.OnsiteContactID;
        });
        if (match != null) c.RequestOnsiteContactID = match.RequestOnsiteContactID;
      }
      self.RequestOnsiteContacts.push(c);
    });

    var ajaxParms = {};
    ajaxParms.requestId = self.RequestID;
    ajaxParms.requestOnsiteContacts = ko.toJS(self.RequestOnsiteContacts());
    ajaxParms.removed = contactsToRemove;

    $.post(RazorShared.baseUrl + 'Request/SaveRequestOnsiteContactList', ajaxParms, function (data) {
      if (!data.success) {
        Common.Dialog('Error saving contact', null, data.error);
        $.unblockUI();
      } else {
        self.$container.modal('hide');
        $.unblockUI();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Save Contact', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
      $.unblockUI();
    });
  };
};

export default RequestOnsiteContactsDialogVM;
