import Cookies from 'js-cookie';
import { Common } from './Common.js';
import DialogMgr from './DialogMgr.js';

var SiteMessageMgmtVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 100;
  self.loadedCount = 0;
  self.cookieName = 'efcSiteMessageSearch';
  var searchCookie = Cookies.getJSON(self.cookieName);
  var filterOn = jsonData.FilterOnCustom == true ? jsonData.SiteMessageSearch : searchCookie || jsonData.SiteMessageSearch;
  self.SiteMessageSearch = new SiteMessageSearch(filterOn, self.defaultListCount);

  self.LoadList = function (callback) {
    $('#loadMessage').show();
    $('#sectSiteMessageList').load(
      RazorShared.baseUrl + 'SiteMessage/GetSiteMessageList',
      ko.toJS(self.SiteMessageSearch),
      function (response, status, xhr) {
        if (status == 'error') Common.Dialog('Search', 'An error has occurred: ' + xhr.status + ' ' + xhr.statusText, null, xhr.status);
        else self.loadedCount = $('#numLoaded').val();
        $('#loadMessage').hide();
      }
    );
    Cookies.set(self.cookieName, ko.toJS(self.SiteMessageSearch), { expires: 30 });
  };

  self.EditSiteMessage = function (siteMessageID, name) {
    DialogMgr.SiteMessageAddEdit(siteMessageID, self.LoadList);
  };

  self.AddSiteMessage = function () {
    DialogMgr.SiteMessageAddEdit(0, self.LoadList);
  };

  self.confirmDeleteID = 0;
  self.DeleteSiteMessage = function (id, name) {
    console.log(name);
    self.confirmDeleteID = id;
    Common.DialogConfirmation('Confirm Delete Site Message', 'Are you sure you want to delete message ' + name + '?', null, function () {
      self.DeleteConfirmed();
    });
  };

  self.DeleteConfirmed = function () {
    if (!self.confirmDeleteID || self.confirmDeleteID == 0) return;

    $.post(RazorShared.baseUrl + 'SiteMessage/DeleteMessage/' + self.confirmDeleteID, function (data) {
      if (data.success) {
        self.LoadList();
        self.confirmDeleteID = 0;
      } else Common.Dialog('Error Deleting Site Message', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Delete Site Message', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };
};

var SiteMessageSearch = function (jsonData, defaultCount) {
  var self = this;

  self.SortBy = jsonData.SortBy || 0;
  self.SortDesc = jsonData.SortDesc || false;
};

export default SiteMessageMgmtVM;
